import React from 'react'

import Layout from 'components/Layout'

const HittaHit = ({ data }) => {
  return (
    <Layout pageTitle='Hitta hit'>
      <oma-grid-row>
        <div className='section'>
          <h1 className='heading page__heading'>Hitta hit</h1>
          <p className='section__text'>
            NaprapatAkuten ligger i Prickshuset mellan Athena gym och
            tandläkarna.
          </p>
          <oma-google-map
            title='Karta för att hitta till NaprapatAkuten'
            placeId='ChIJP1PBO7OaYEYRo9Zq57YTJjA'
            zoom='15'
            width='100%'
            height='500px'
            apikey='AIzaSyDPfC6CzYHGv5BvL5H9nUKxyqhVjQaxdNs'
          />
        </div>
      </oma-grid-row>
    </Layout>
  )
}

export default HittaHit
